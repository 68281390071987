import { events, useFrame } from '@react-three/fiber'
import { OrbitControls, PerspectiveCamera, useTexture,  } from '@react-three/drei'
import { useRef, useEffect, useState} from 'react'
import { Perf } from 'r3f-perf'
import { motion } from "framer-motion-3d"
import { MotionConfig } from "framer-motion"

export default function Experience()
{

    let oneDeg= Math.PI*2/360

    const loadingScreen =  document.querySelector("#loadingScreen")
    const twoThree =  document.querySelector("#twoThree")

    const portrait = useRef()
    const camera = useRef()

    const [canvasSize, setCanvasSize] = useState({x:window.innerWidth,y:window.innerHeight})
    const [mousePos, setMousePos] = useState({x:0,y:0})
    const [normalPos, setNormalPos] = useState({x:0,y:0})
    const [twoThreeOver, setTwoThreOver] = useState(false)
    const [portraitRot, setPortraitRot] = useState({x:0,y:0,z:0})

    twoThree && twoThree.addEventListener("mouseenter", ()=>{setTwoThreOver(true)})
    twoThree.addEventListener("mouseleave", ()=>{setTwoThreOver(false)})

    useFrame((state, delta) =>
    {
        
        portrait.current.rotation.y =  (portrait.current.rotation.y - (oneDeg * normalPos.x * 1  )) * 0.89
        portrait.current.rotation.x =  ((portrait.current.rotation.x - (oneDeg * normalPos.y * 0.75 )) * 0.89) + (oneDeg*0.2)
    })

    // Mouse Movement
    useEffect(() => {
        const handleMouseMove = (event) => {
          setMousePos({ x: event.clientX, y: event.clientY });
        }
        window.addEventListener('mousemove', handleMouseMove);
      }, [])
      useEffect(()=>{
        setNormalPos( {x:( mousePos.x / (canvasSize.x/2)  -1), y : (mousePos.y/(canvasSize.y/2)  -1)})
    
        console.log( portrait.current.rotation)

      },[mousePos])
      

    const [bodyColorMap, bodyAlphaMap, thinLines, hair,eyesColor, eyesMatte, bodyLines] = useTexture([
        'portrait_color_2.jpg',
        'portrait_matte.jpg',
        'portrait_thinLines.jpg',
        'portrait_hair.jpg',
        'portrait_eyesColor.jpg',
        'portrait_eyesMatte.jpg',
        'portrait_bodyLines.jpg',
    ])

    loadingScreen.setAttribute("style", "opacity:0")
    
    return <>
        {/* <Perf position="top-right"/> */}

            <PerspectiveCamera makeDefault  
                    fov= {25}
                    near= {0.1}
                    far= {200}
                    position= {[ 0, 0.04, 3.5]}
                    ref={ camera } 
                />

     
        <motion.group ref={ portrait } initial={{x:-.3,z:3}} animate={{x:0,z: 0 }} transition={{ ease: "easeInOut", duration: 1 }}>
            <motion.mesh position-z={ 0.12 } animate={{z: twoThreeOver ? 0.36 : 0.12}} transition={{ ease: "easeInOut", duration: .22 }}>
                <planeGeometry args={[1, 1.25]}/>
                <meshBasicMaterial color={"#000000"} transparent  alphaMap={thinLines} toneMapped={ false }/>
            </motion.mesh>
            
            <motion.mesh position-z={ 0.06 } animate={{z: twoThreeOver ? 0.18 : 0.06}} transition={{ ease: "easeInOut", duration: .22 }}>
                <planeGeometry args={[1, 1.25]}/>
                <meshBasicMaterial map={eyesColor} transparent  alphaMap={eyesMatte} toneMapped={ false }/>
            </motion.mesh>

            <motion.mesh position-z={ 0.03 } animate={{z: twoThreeOver ? 0.12 : 0.03}} transition={{ ease: "easeInOut", duration: .22 }}>
                <planeGeometry args={[1, 1.25]}/>
                <meshBasicMaterial color={"#9dcc20"} transparent  alphaMap={hair} toneMapped={ false }/>

            </motion.mesh>

            <motion.mesh position-z={ -0.03 } animate={{z: twoThreeOver ? -0.12 : -0.03}} transition={{ ease: "easeInOut", duration: .22 }}>
                <planeGeometry args={[1, 1.25]}/>
                <meshBasicMaterial color={"#888888"} transparent  alphaMap={bodyLines} toneMapped={ false }/>

            </motion.mesh>

            <motion.mesh position-z={ -0.06 } animate={{z: twoThreeOver ? -0.36: -0.06, scale: twoThreeOver?1.03:1}} transition={{ ease: "easeInOut", duration: .22 }}>
                <planeGeometry args={[1, 1.25]} />
                <meshBasicMaterial map={bodyColorMap} transparent  alphaMap={bodyAlphaMap} toneMapped={ false }/>
            
            </motion.mesh>
            <motion.mesh position-x={0.2} position-y={-0.2} position-z={ 0.5} animate={{z: twoThreeOver ? 1 : 0.5}} transition={{ ease: "easeInOut", duration: .22 }} opacity={0.6}>
                <circleGeometry args={[.03, 32]}/>
                <meshBasicMaterial color={"#F1FFEB"} toneMapped={ false } transparent opacity={0.5}/>

            </motion.mesh>
            <motion.mesh position-x={-0.1} position-y={0.3} position-z={ -0.9} animate={{z: twoThreeOver ? -2 : -0.9}} transition={{ ease: "easeInOut", duration: .22 }} opacity={0.6}>
                <circleGeometry args={[.4, 32]}/>
                <meshBasicMaterial color={"#F1FFEB"} transparent toneMapped={ false }/>
            </motion.mesh>
        </motion.group>


       
       


       
       
    </>
}