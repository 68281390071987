import { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { Canvas } from '@react-three/fiber'
import Experience from './Experience.jsx'

import './style.css'


const root = ReactDOM.createRoot(document.querySelector('#root'))

root.render(
    <>
        <Suspense fallback={'loading'}>
            <Canvas>
                <Experience/>    
            </Canvas>
        </Suspense>
    </>
)